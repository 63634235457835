<template lang="html">
  <div v-if="isEnded">Ended.</div>

  <div v-else class="flex flex-wrap">
    <div class="mr-2">
      <span style="color: #009647" class="font-bold">{{ days }}</span>
      <span class="text-3xl">Day{{ days !== 1 ? 's' : '' }}</span>
    </div>
    <div class="mr-2">
      <span style="color: #d8222a" class="font-bold">{{ hours }}</span>
      <span class="text-3xl">Hour{{ hours !== 1 ? 's' : '' }}</span>
    </div>
    <div class="mr-2">
      <span style="color: #000000" class="font-bold">{{ minutes }}</span>
      <span class="text-3xl">Minute{{ minutes !== 1 ? 's' : '' }}</span>
    </div>
    <div class="mr-2">
      <span style="color: #000000" class="font-bold">{{ seconds }}</span>
      <span class="text-3xl">Second{{ seconds !== 1 ? 's' : '' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endDate: {
      type: Date,
    },
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
    };
  },
  methods: {
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },

    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.isEnded = true;
      }
    },
  },

  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },

  destroy() {
    clearInterval(this.timer);
  },
};
</script>
