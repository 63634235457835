<template>
  <div id="app">
    <div
      class="flex w-screen xl:flex-row lg:flex-row md:flex-col-reverse sm:flex-col-reverse flex-col-reverse justify-center"
      style="height: 100vh"
    >
      <div
        class="p-12 py-24 xl:w-1/2 lg:w-1/2 md:w-screen sm:w-screen w-screen flex flex-col"
      >
        <img src="assets/clogo.png" />
        <div v-if="false" class="text-5xl mb-4">
          <count-down :end-date="Date.parse('01 Jan 2024 00:00:00')" />
        </div>
        <div class="font-bold">
          Join us on <span class="font-black">Jan 13th, 2024</span> for the global day of action
          for Gaza. The world will be marching, join us!
        </div>
        <div class="mt-8">
            <a
              class="btn-slide"
              target="_blank"
              href="breifs/Global Action Day Brief.pdf"
              download
            >
              <span class="circle"><i class="fas fa-book-open"></i></span>
              <span class="title">Global Action Day Brief</span>
              <span class="title-hover">Download</span>
              
            </a>
          </div>
        <div
          class="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-4 mt-4"
        >
          <div class="">
            <a
              class="btn-slide"
              target="_blank"
              href="breifs/1. Banner Printout.pdf"
              download
            >
              <span class="circle"><i class="fa-solid fa-download"></i></span>
              <span class="title">Banner - Printout</span>
              <span class="title-hover">Download</span>
            </a>
          </div>

          <div class="">
            <a
              class="btn-slide2"
              target="_blank"
              href="breifs/3. Hrizontal A0 Banner.pdf"
              download
            >
              <span class="circle2"><i class="fa-solid fa-download"></i></span>
              <span class="title2">Horizontal - Banner</span>
              <span class="title-hover2">Download</span>
            </a>
          </div>
          <div class="">
            <a
              class="btn-slide3"
              target="_blank"
              href="breifs/2. Vertical A0 Banner.pdf"
              download
            >
              <span class="circle3"><i class="fa-solid fa-download"></i></span>
              <span class="title3">Vertical - Banner</span>
              <span class="title-hover3">Download</span>
            </a>
          </div>
          <div class="">
            <a
              class="btn-slide"
              target="_blank"
              href="breifs/Campaign Logo.pdf"
              download
            >
              <span class="circle"><i class="fa-solid fa-download"></i></span>
              <span class="title">Campaign - Logo</span>
              <span class="title-hover">Download</span>
            </a>
          </div>
        </div>
        <div class="grow mt-4"></div>
        <div class="flex">
          <a
            class="text-2xl mr-4"
            target="_blank"
            href="https://www.instagram.com/countdown2ceasefire/?igsh=Y3YxNnE5Zm1kbGE3&utm_source=qr"
          >
            <i style="color: #009647" class="fa-brands fa-instagram"></i>
          </a>
          <a
            class="text-2xl mr-4"
            target="_blank"
            href="https://www.tiktok.com/@countdown2ceasefire?_t=8iNI7wTkC3y&_r=1"
          >
            <i style="color: #d8222a" class="fa-brands fa-tiktok"></i>
          </a>
          <a
            class="text-2xl mr-4"
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61554909955915"
          >
            <i style="color: #000000" class="fa-brands fa-facebook"></i>
          </a>
          <a
            class="text-2xl mr-4"
            target="_blank"
            href="https://t.me/countdown2ceasefire"
          >
            <i style="color: #2292ba" class="fa-brands fa-telegram"></i>
          </a>
          <a
            class="text-2xl mr-4"
            target="_blank"
            href="https://x.com/c2ceasefire?s=21"
          >
            <i style="color: #009647" class="fa-brands fa-x-twitter"></i>
          </a>
        </div>
      </div>
      <div
        v-if="false"
        class="xl:w-1/2 lg:w-1/2 md:w-screen sm:w-screen w-screen"
      >
        <iframe
          class="w-full h-screen"
          src="https://www.youtube.com/embed/WlC4GiIp6Do?si=XbJr1_k_oyBu4mlF"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from './components/CountDown.vue';

export default {
  name: 'App',
  components: {
    CountDown,
  },
};
</script>

<style>
#app {
  font-family: Raleway, Noto Sans Arabic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0px;
  margin: 0px;
  background-color: #f9f9f9;
}

.btn-slide,
.btn-slide2,
.btn-slide3 {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100%;
  line-height: 50px;
  padding: 0;
  border-radius: 50px;
  background: #fdfdfd;
  border: 2px solid #009647;
  transition: 0.5s;
}

.btn-slide2 {
  border: 2px solid #d8222a;
}

.btn-slide3 {
  border: 2px solid #000000;
}

.btn-slide:hover {
  background-color: #009647;
}

.btn-slide2:hover {
  background-color: #d8222a;
}

.btn-slide3:hover {
  background-color: #000000;
}

.btn-slide:hover span.circle,
.btn-slide2:hover span.circle2,
.btn-slide3:hover span.circle3 {
  left: 100%;
  margin-left: -45px;
  background-color: #fdfdfd;
  color: #009647;
}

.btn-slide2:hover span.circle2 {
  color: #d8222a;
}

.btn-slide3:hover span.circle3 {
  color: #000000;
}

.btn-slide:hover span.title,
.btn-slide2:hover span.title2,
.btn-slide3:hover span.title3 {
  left: 40px;
  opacity: 0;
}

.btn-slide:hover span.title-hover,
.btn-slide2:hover span.title-hover2,
.btn-slide3:hover span.title-hover3 {
  opacity: 1;
  left: 40px;
}

.btn-slide span.circle,
.btn-slide2 span.circle2,
.btn-slide3 span.circle3 {
  display: block;
  background-color: #009647;
  color: #fff;
  position: absolute;
  float: left;
  margin: 3px;
  line-height: 42px;
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
  text-align: center;
}

.btn-slide2 span.circle2 {
  background-color: #d8222a;
}
.btn-slide3 span.circle3 {
  background-color: #000000;
}

.btn-slide span.title,
.btn-slide span.title-hover,
.btn-slide2 span.title2,
.btn-slide2 span.title-hover2,
.btn-slide3 span.title3,
.btn-slide3 span.title-hover3 {
  position: absolute;
  left: 60px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #009647;
  transition: 0.5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: #d8222a;
  left: 60px;
}

.btn-slide3 span.title3,
.btn-slide3 span.title-hover3 {
  color: #000000;
  left: 60px;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2,
.btn-slide3 span.title-hover3 {
  left: 80px;
  opacity: 0;
}

.btn-slide span.title-hover,
.btn-slide2 span.title-hover2,
.btn-slide3 span.title-hover3 {
  color: #fff;
}
</style>
